//import '@/styles/globals.scss'
import type { AppProps } from "next/app"
import "@splidejs/react-splide/css"
//import 'primereact/resources/themes/lara-light-indigo/theme.css'
import { appWithTranslation } from "next-i18next"
import { Header } from "@/bet-nextv1/Header"
import { Toaster } from "react-hot-toast"
import { useEffect, useState } from "react"
import { FooterMobile } from "@/bet-nextv1/FooterMobile"
import { useResponsive } from "@/core-nextv3/util/useResponsive"
import { NavigationSports } from "@/bet-nextv1/NavigationSports"
import { BetSlip } from "@/bet-nextv1/BetSlipe"
import { FirebaseProvider } from "@/bet-nextv1/context/FirebaseContext"
import { AuthProvider } from "@/bet-nextv1/context/AuthContext"
import { Loading } from "@/bet-nextv1/Loading"
import { MessageModal } from "@/bet-nextv1/MessageModal"
import { MaintanceModal } from "@/bet-nextv1/MaintanceModal"
import { useCore } from "@/core-nextv3/core/core"
import { call } from "@/core-nextv3/util/call.api"
import { useSubCore } from "@/hooks/useSubCore"
import Types from "@/core-nextv3/type"
import { ACCOUNT_SETTING, THEME_SETTING } from "@/setting/setting"
import { useRouter } from "next/router"
import { HeaderTwo } from "@/bet-nextv1/HeaderTwo"
import GlobalClasses from "@/styles/globalClasses"
import AltStyles from "@/styles/alt"
import { HeaderThree } from "../HeaderThree"
import { NavigationSportsTwo } from "../NavigationSportsTwo"
import { FooterMobileTwo } from "../FooterMobileTwo"

const AppPageTwo = ({ Component, pageProps, MenuComponent = NavigationSportsTwo }: any) => 
{
    const { isDesktop }                                                  = useResponsive()
    const [ maintance, setMaintance ]                                    = useState(false)
    const { setSelectedLanguage, selectedLanguage, setAwaitLoadingHTTP } = useCore()
    const { setSelectedTimezone }                                        = useSubCore()
    const router                                                         = useRouter()

    const fetchTimezone = async () => 
    {
        let timezone: any

        if (selectedLanguage === "kr") 
        {
            timezone = { id : "Asia/Seoul", label : "Asia/Seoul", value : "+09:00" }
        }
        else if (selectedLanguage === "pt") 
        {
            timezone = {
                id    : "America/Sao_Paulo",
                label : "America/Sao_Paulo",
                value : "-03:00",
            }
        }
        else 
        {
            timezone = { id : "Asia/Seoul", label : "Asia/Seoul", value : "+09:00" }
        }

        await call(
            Types.SET_TIMEZONE_UTIL_SERVER,
            ACCOUNT_SETTING.merge({
                timezone : timezone,
            })
        )

        setSelectedTimezone(timezone?.label)
    }

    useEffect(() => 
    {
        fetchTimezone()
        setSelectedLanguage(pageProps.locale ?? "kr")
    }, [])

    useEffect(() => 
    {
        const script = document.createElement("script");
        script.src   = "https://www.google.com/recaptcha/enterprise.js?render=" + process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
        script.async = true;
        document.head.appendChild(script);
    }, []);

    if (router.pathname.includes("404")) 
    {
        return <Component {...pageProps} />
    }

    if (router.asPath.includes("debug")) 
    {
        return <Component {...pageProps} />
    }

    const getHeader = () => 
    {
        if (THEME_SETTING.headerType == "alt") 
        {
            return <HeaderTwo {...pageProps} />
        }
        else 
        {
            return <HeaderThree {...pageProps} />
        }
    }

    const getLayout = () => 
    {
        if (router?.asPath === "/" && THEME_SETTING.redirectType === "loginPage") 
        {
            return (
                <>
                    <div className='indexPage'>
                        <Component {...pageProps} />
                    </div>
                </>
            )
        }
        else 
        {
            return (
                <>
                    {!THEME_SETTING.headerInMiddle && getHeader()}
                    <div className='layoutPages'>
                        <div className='layoutRight'>
                            <NavigationSportsTwo account={pageProps.account} sizeBanners={pageProps.sizeBanners}/>
                        </div>
                        <div className='middle'>
                            {THEME_SETTING.headerInMiddle && getHeader()}
                            <Component {...pageProps} />
                        </div>
                    </div>
                </>
            )
        }
    }

    
    return (
        <>
            {/* <GoogleReCaptchaProvider
                reCaptchaKey={"" + process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                scriptProps={{
                    async    : false,
                    defer    : false,
                    appendTo : "head",
                    nonce    : undefined,
                }}
            > */}
            <FirebaseProvider>
                <AuthProvider>
                    <GlobalClasses />
                    <AltStyles />
                    {/* {getHeader()} */}
                    <div className='contentWrapper' style={{ height : "100dvh", overflowY : "hidden", display : "flex", flexDirection : "column" }}>
                        {getLayout()}
                        {!isDesktop && <FooterMobileTwo {...pageProps} />}
                    </div>

                    

                    <Toaster
                        position='top-center'
                        reverseOrder={true}
                        toastOptions={{
                            duration : 3000,
                        }}
                        containerStyle={{
                            zIndex : 999999,
                        }}
                    />

                    <Loading />

                    <MessageModal account={pageProps.account} />

                    {maintance && <MaintanceModal />}
                </AuthProvider>
            </FirebaseProvider>
            {/* </GoogleReCaptchaProvider> */}
        </>
    )
}

export default appWithTranslation(AppPageTwo)
