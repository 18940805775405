import "@/styles/globals.scss"
import AppPageTwo from "@/bet-nextv1/AppPageTwo";
import { AnimatedMenu } from "@/components/AnimatedMenu";

function MyApp({ Component, pageProps }: any) 
{
    return (
        <AppPageTwo Component={Component} MenuComponent={AnimatedMenu} pageProps={pageProps} />
    )
 
    
}

export default MyApp;
