import styles from "./styles.module.scss"
import { BsArrowRightShort } from "react-icons/bs"
import { AccordionSport } from "./AccordionSport"
import { AccordionSports } from "./AccordionSports"
import { AccordionSportItem } from "./AccordionSportItem"
import { useResponsive } from "@/core-nextv3/util/useResponsive"
import { useSubCore } from "@/hooks/useSubCore"
import { useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import { useCore } from "@/core-nextv3/core/core"
import { doc, onSnapshot } from "firebase/firestore"
import { FirebaseContext } from "../context/FirebaseContext"
import { HeaderForMobileMenu } from "../HeaderForMobileMenu/index,"
import { getFlag } from "@/bet-nextv1/utils/getFlag"
import { useTranslation } from "next-i18next"
import { HighlightsWidget } from "../HighlightsWidget"
import { AuthContext } from "../context/AuthContext"
import redirectTo from "@/bet-nextv1/utils/redirectTo"

export const NavigationSports = ({
    closePopularEvents,
    openModalPopularEvents,
}: any) => 
{
    const [ navigation, setNavigation ] = useState<any>()

    const {
        games,
        locations,
        setSelectedLeague,
        setSelectedLocation,
        setSelectedSport,
        setReloadGames,
        setVirtual,
        setEndDate,
        setStartDate,
        setFilterPerLeague,
        setOpenLoginModal
    } = useSubCore()
    const { isDesktop }                             = useResponsive()
    const router                                    = useRouter()
    const { setAwaitLoadingHTTP, selectedLanguage } = useCore()
    const firebaseApp                               = useContext(FirebaseContext)
    const [ toggle, setToggle ]                     = useState(false)
    const { t }                                     = useTranslation()

    const { user } = useContext(AuthContext)

    useEffect(() => 
    {
        onSnapshot(
            doc(firebaseApp.firestore, "default/fixtureMenu/flatFixtureMenu/default"),
            async (querySnapshot) => 
            {
                const data         = querySnapshot.data()?.data
                const desiredOrder = [ 6046, 48242, 154914, 154830, 35232 ]

                const sorted = data?.sort((a: any, b: any) => 
                {
                    const indexA = desiredOrder.indexOf(parseInt(a.id))
                    const indexB = desiredOrder.indexOf(parseInt(b.id))

                    if (indexA === -1 && indexB === -1) return 0
                    if (indexA === -1) return 1
                    if (indexB === -1) return -1
                    return indexA - indexB
                })



                setNavigation(sorted)
            }
        )
    }, [ games, locations ])

    const handleSelectCountry = async (id: any, sport: any) => 
    {
        if (router?.asPath !== "/sports/") 
        {
            setAwaitLoadingHTTP(true)
        }

        // setSelectedSport(sport.id ? sport.id : "esports")
        //setVirtual(sport.id ? false : true)
        setSelectedLocation(id)
        // setSelectedLeague(null)
        setFilterPerLeague(true)
        setStartDate(null)

        if (router?.asPath !== "/sports/") 
        {
            redirectTo("/sports/", user, router, setOpenLoginModal)
        }
        else 
        {
            setReloadGames(true)
        }

        if (closePopularEvents) 
        {
            closePopularEvents(false)
        }

        setAwaitLoadingHTTP(false)
    }

    const handleSelectLeague = async (league: any, sport: any) => 
    {
        if (router?.asPath !== "/sports/") 
        {
            setAwaitLoadingHTTP(true)
        }

        setSelectedSport(sport.id ? sport.id : "esports")
        setVirtual(sport.id ? false : true)

        setSelectedLeague(league)

        setEndDate(null)
        setSelectedLocation(null)

        setReloadGames(true)

        if (router?.asPath !== "/sports/") 
        {
            redirectTo("/sports/", user, router, setOpenLoginModal)
        }

        if (closePopularEvents) 
        {
            closePopularEvents(false)
        }

        setAwaitLoadingHTTP(false)
    }


    if (!isDesktop && openModalPopularEvents == true) 
    {
        return (
            <div className={styles.navigationSportsMobile}>
                <div className={styles.container}>
                    <HeaderForMobileMenu closeFunction={closePopularEvents} />

                    <div className={styles.content}>
                        <AccordionSports
                            title={t("Esportes")}
                            image={<img src='/assets/img/sportscash.png' alt='' />}
                        >
                            {navigation?.length > 0 &&
                navigation?.map((sport: any, key: any) => (
                    <AccordionSport
                        key={key}
                        sport={sport}
                        quantity={sport?.count}
                        title={
                            selectedLanguage === "pt"
                                ? sport?.name_br
                                : sport?.name_ko
                        }
                        handler={closePopularEvents}
                    >
                        {sport?.children?.map((location: any, key2: any) => (
                            <AccordionSportItem
                                key={key2}
                                title={
                                    selectedLanguage === "pt"
                                        ? location?.name_br
                                        : location?.name_ko
                                }
                                image={<img src={getFlag(location)} alt='' />}
                                quantity={location?.count}
                                id={location.id}
                                sport={sport}
                                handler={handleSelectCountry}
                            >
                                {location?.children?.map((league: any, key3: any) => (
                                    <div
                                        key={key3}
                                        onClick={() => handleSelectLeague(league, sport)}
                                        className={styles.child}
                                    >
                                        <p className={styles.name}>
                                            {selectedLanguage === "pt"
                                                ? league?.name_br
                                                : league?.name_ko}
                                        </p>
                                        <p className={styles.name}>{league?.count}</p>
                                        <BsArrowRightShort />
                                    </div>
                                ))}
                            </AccordionSportItem>
                        ))}
                    </AccordionSport>
                ))}
                        </AccordionSports>

                        {/* <AccordionSports
              title={t('Liga Popular')}
              image={<BsFillTrophyFill />}
            ></AccordionSports> */}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.navigationSports}>
            <div className={styles.navigationGrid}>
                <div
                    className={`${styles.subMenuGridItem} ${styles.firstRow}`}
                    onClick={() => redirectTo("/deposit?tabIndex=0", user, router, setOpenLoginModal)}
                >
                    <img src='/assets/img/deposito.png' alt='' />
                    <a
                        className={styles.title}
                    >
                        {t("Depósito")}
                    </a>
                </div>

                <div
                    className={`${styles.subMenuGridItem} ${styles.firstRow}`}
                    onClick={() => redirectTo("/deposit?tabIndex=1", user, router, setOpenLoginModal)}
                >
                    <img src='/assets/img/payout.png' alt='' />
                    <a
                        className={styles.title}

                    >
                        {t("Saque")}
                    </a>
                </div>

                <div
                    className={`${styles.subMenuGridItem} ${styles.firstRow}`}
                    onClick={() => redirectTo("/deposit?tabIndex=3", user, router, setOpenLoginModal)}
                >
                    <img src='/assets/img/customer.png' alt='' />
                    <a
                        className={styles.title}

                    >
                        {t("Perguntas")}
                    </a>
                </div>

                <div
                    className={styles.subMenuGridItem}
                    onClick={() => 
                    {
                        setReloadGames(true);
                        setSelectedSport(null);
                        redirectTo("/sports", user, router, setOpenLoginModal)
                    }}
                >
                    <a
                        className={
                            router.asPath === "/sports/" ? styles.titleActive : styles.title
                        }

                    >
                        {t("Esporte")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/liveSports", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/liveSports/"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Live Sports")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/deposit?tabIndex=5", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/deposit/?tabIndex=5"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Histórico de Apostas")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/mini/mgm/baccarat/1", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/mini/mgm/baccarat/1/"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Mini Game")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/casino", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/casino/" ? styles.titleActive : styles.title
                        }

                    >
                        {t("Cassino")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/deposit?tabIndex=2", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/deposit/?tabIndex=2"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Movimentação")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/event", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/event/" ? styles.titleActive : styles.title
                        }

                    >
                        {t("Evento")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/deposit?tabIndex=7", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/deposit/?tabIndex=7"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Avisos / Regras")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/deposit?tabIndex=6", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/deposit/?tabIndex=6"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Histórico de Cash")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => 
                {
                    redirectTo("/mini/boscore/ladder/1/", user, router, setOpenLoginModal) 
                }}>
                    <a
                        className={
                            router.asPath.includes("boscore") ? styles.titleActive : styles.title
                        }

                    >
                        {t("Boscore")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => 
                {
                    redirectTo("/mini/lotus/baccarat/1/", user, router, setOpenLoginModal) 
                }}>
                    <a
                        className={
                            router.asPath.includes("lotus") ? styles.titleActive : styles.title
                        }

                    >
                        {t("Lotus")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => 
                {
                    redirectTo("/mini/mgm/baccarat/1/", user, router, setOpenLoginModal) 
                }}>
                    <a
                        className={
                            router.asPath.includes("mgm") ? styles.titleActive : styles.title
                        }

                    >
                        {t("MGM")}
                    </a>
                </div>
            </div>

            <AccordionSports
                title={t("Esportes")}
                image={<img src='/assets/img/sportscash.png' alt='' />}
            >
                {navigation?.length > 0 &&
          navigation?.map((sport: any, key: any) => (
              <AccordionSport
                  key={key}
                  quantity={12}
                  title={
                      selectedLanguage === "pt" ? sport?.name_br : sport?.name_ko
                  }
                  sport={sport}
              >
                  {sport?.children?.map((location: any, key2: any) => (
                      <AccordionSportItem
                          key={key2}
                          title={
                              selectedLanguage === "pt"
                                  ? location?.name_br
                                  : location?.name_ko
                          }
                          image={<img src={getFlag(location)} alt='' />}
                          quantity={location?.count}
                          id={location.id}
                          sport={sport}
                          handler={handleSelectCountry}
                      >
                          {location?.children?.map((league: any, key3: any) => (
                              <div
                                  key={key3}
                                  onClick={() => handleSelectLeague(league, sport)}
                                  className={styles.child}
                              >
                                  <p className={styles.name}>
                                      {selectedLanguage === "pt"
                                          ? league?.name_br
                                          : league?.name_ko}
                                  </p>
                                  <div>
                                      <p>{league?.count}</p>
                                      <BsArrowRightShort />
                                  </div>
                              </div>
                          ))}
                      </AccordionSportItem>
                  ))}
              </AccordionSport>
          ))}
            </AccordionSports>

            {/* <AccordionSports
        title={t('Liga Popular')}
        image={<BsFillTrophyFill />}
      ></AccordionSports> */}

            <HighlightsWidget type={"games"}></HighlightsWidget>
            <HighlightsWidget type={"leagues"}></HighlightsWidget>
        </div>
    )
}
