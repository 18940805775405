import { TDate } from "@/core-nextv3/model/TDate";
// import { format } from 'date-fns';
// import { toZonedTime } from 'date-fns-tz';

const dateFormat = (value: any, timezone:any = "Asia/Seoul") => 
{
    // Parse de uma string para um objeto Date 
    //const utcDate       = parse(value, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());
    if (value)
    {
        const date = new TDate({ value : value });

        return date.format("yyyy-MM-dd HH:mm:ss", { label : timezone });

        // if(value._seconds)
        // {
        //     value = new Date(1970, 0, 1).setSeconds(value._seconds)
        // }
        // const zonedDateTime:any = toZonedTime(value, timezone);
        
        // try{
        //     return format(zonedDateTime, 'yyyy-MM-dd HH:mm:ss');
        // }
        // catch(e){
        //     console.error("Invalid date", value, zonedDateTime);
        //     return ""
        // }        
    }    
};

export { dateFormat };

